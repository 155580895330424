import axios from 'axios';
import Swal from 'sweetalert2';

const instance = axios.create({
    baseURL: "https://epp5services.eplannerpro.com/"
})

instance.interceptors.request.use(function (config) {
    let objStorage = localStorage.getItem('persist:v711-demo1-auth');

    if (objStorage) {
        let authData = JSON.parse(objStorage);
        if (authData.user) {
            let userData = JSON.parse(authData.user);
            config.headers = {
                'Authorization': "Bearer " + userData.token,
                'CompanyToken': userData.ffCompanyId
            }
        }
    }
    return config;
});

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        Swal.fire({
            icon: 'error',
            title: "Unauthorized Access!!!",
            text: 'You will be redirected to login',
            timer: 3000,
        }).then((result) => {
            localStorage.clear();
            window.location = '/auth/login';
        });
    } else {
        return Promise.reject(error);
    }
});

export default instance