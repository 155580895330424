//import axios from "axios";
import axios from '../../../../httpAxios'


export const LOGIN_URL = "api/Authenticate/login";
export const LOGOUT_URL = "api/Authenticate/logout";
export const REGISTER_URL = "api/Authenticate/register-admin";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const TimeZoneURL = "api/company/get-all-timezones";
export const GetCompanyDetails = "/api/company/company-details";
export const SaveUpdateCompany = "/api/company/update-company";
export const CheckUserForCompany = "/api/Authentic/check-user-exist-in-company"

export const ME_URL = "api/me";

export function login(domainname,email, password) {
  debugger
    const loginData = {
      DomainName:domainname,
    Username: email,
    Password: password
  }
  return axios.post(LOGIN_URL,loginData);
}
export function logout() {
    return axios.post(LOGOUT_URL);
}
export function register(email, firstName, lastname,company,eventId,phonenum) {
  const payload = {
    Username: email,
    Email: email,
    FirstName:firstName,
    LastName:lastname,
    Name:company,
    PhoneNumber:phonenum
}
  return axios.post(REGISTER_URL,payload);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}


export function forgotpwd(email) {
  return axios.get('/api/Authenticate/forgot-password?Email=' + email);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}


export function getTimeZoneData() {
  return axios.get(TimeZoneURL);
}

export function getCompanyUserDetail(id,userid) {
  return axios.get(GetCompanyDetails+"?Id="+ id+"&UserId="+userid);
}


export function resetpwd(userid,password,token) {
  const payload = {
    UserId: userid,
    Password: password,
    Token:token
}
  return axios.post("/api/Authenticate/reset-password",payload);
 
}


export function SaveUpdateCompanyDetails(companyobj) {
   return axios.post(SaveUpdateCompany,companyobj);
}

export function getTimeZoneDataByCountry(countryid) {
  return axios.get("api/company/get-timezones-by-country?CountryId="+countryid);
}


export function getCountries() {
  return axios.get("/api/company/get-all-countries");
}

export function IsCompanyHostHeaderExist(compid,hostheader) {
  return axios.get("/api/company/company-hostheader-exists?CompanyId="+compid+"&HostHeader="+hostheader);
}

export function checkUserForCompany() {
    return axios.get("api/")
}
