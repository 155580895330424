/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import About from "./modules/About/AboutUs";
export function Routes() {
    const location = useLocation()
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    return (
        <Switch>
            {/* <Route path="/about" component={About}/> */}
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/

                <Route >
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/" />
            )}

            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />

            {isAuthorized == false &&
                //&& window.location.pathname != "/auth/login"
                //&& window.location.pathname != "/auth/registration"
                //&& window.location.pathname != "/auth/forgot-password"
                !window.location.pathname.includes("auth/") &&
                window.location.pathname.includes("reset")
                ? (
                /*Redirect to `/auth` when user is not authorized*/
                //<Redirect to="/auth/login" />
                window.location = '/auth/login'
            ) : (
                <Layout>
                    <BasePage />
                </Layout>
            )}
        </Switch>
    );
}
