export const globalvar = {    
    FFApiBaseUrl: 'https://epp5ffapi.eplannerpro.com/'
};

export const blueColoredStyle = {
    color: "#26a69a"
};

export const SettingConstants = {
  SupportEmail: 'Support@eplannerpro.com',
  DomainName: 'eplannerpro.com'
}

export const PrimaryCompany = {
  CompanyToken: '9b933e89-8943-4886-a129-b49abaead6cf'
}

