import React, { useState,useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { resetpwd } from "../_redux/authCrud";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const initialValues = {
    password: "",
    confirmpassword:"",
};

function ResetPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userids, setUserId] = useState("");
  const [tokenpwd,setTokenPwd] = useState("");
  
  const ForgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    confirmpassword: Yup.string()
    .required('Please Enter your confirm password')
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  useEffect(() => {
      debugger;
    let id = props.match.params && props.match.params.userid ? props.match.params.userid : "";
    let token = props.match.params && props.match.params.token1 ? props.match.params.token1 : "";
    setTokenPwd(token);
    setUserId(id);
    
   
}, []);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      resetpwd(userids,values.password,tokenpwd)
      .then(response => {
        debugger;
        if(response.data){
          if(response.data.status=='Success' || response.data.status=='success'){
            toast.success(response.data.message)            
            setIsRequested(true);      
            props.history.push('/auth/login');
          }
          else
          {
            toast.error('Something Went Wrong')                
          }
        }        
        setIsRequested(false);
        setSubmitting(false);
        setLoading(false);
      })  .catch(error  => {                        
        debugger;
        if (error.response) {
          //  error.response.data.message
        }
        setIsRequested(false);
          setSubmitting(false);
          setLoading(false);
        setStatus(
          error.response.data.message
          // intl.formatMessage({
          //   id: "AUTH.VALIDATION.INVALID_LOGIN",
          // })
        );
      
    });           
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Reset Password</h3>
            <div className="text-muted font-weight-bold">
             
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "confirmpassword"
            )}`}
            name="confirmpassword"
            {...formik.getFieldProps("confirmpassword")}
          />
          {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.confirmpassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
              <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
