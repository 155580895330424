import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import {useSelector} from "react-redux";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const initialValues = {
  firstname: "",
  company:"",
  phonenum:"",
  email: "",
  lastname: "",
  password: "",
  confirmpassword: "",
  acceptTerms: true,
};

function Registration(props) {
  debugger
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const {user} = useSelector(state => state.auth);
  const RegistrationSchema = Yup.object().shape({
    phonenum: Yup.string()
    .min(10, "Invalid Phone Number")
    .max(14, "Maximum 14 symbols")
    .matches(phoneRegExp, 'Phone number is not valid'),
    company: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(100, "Maximum 100 symbols")
    .required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    firstname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
      lastname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
 
    // acceptTerms: Yup.bool().required(
    //   "You must accept the terms and conditions"
    // ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting,resetForm}) => {
      setSubmitting(true);
      enableLoading();
      register(values.email, values.firstname,values.lastname,values.company,426,values.phonenum)
        .then(response => {
          debugger;
          if(response.data.status=='Success'){
            toast.success(response.data.message)
          //  alert()
            resetForm(initialValues)
            props.history.push("/auth/login")
            //clearStateHandler();
          
          }
          if(response.data.status=='Exists'){
            toast.error('User already exists')       
          }
          if(response.data.status=='Error'){
            alert('User not created');
          }
          //props.register(accessToken);
          disableLoading();
          setSubmitting(false);
        }).catch(error  => {                        
          debugger;
          if (error.response) {
            //  error.response.data.message
          }
          setSubmitting(false);
          setStatus(
            error.response.data.message
            // intl.formatMessage({
            //   id: "AUTH.VALIDATION.INVALID_LOGIN",
            // })
          );
          disableLoading();
      });     
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Company Name*"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "company"
            )}`}
            name="company"
            {...formik.getFieldProps("company")}
          />
          {formik.touched.company && formik.errors.company ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.company}</div>
            </div>
          ) : null}
        </div>
        {/* begin: FirstName */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="First Name*"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "firstname"
            )}`}
            name="firstname"
            {...formik.getFieldProps("firstname")}
          />
          {formik.touched.firstname && formik.errors.firstname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.firstname}</div>
            </div>
          ) : null}
        </div>
        {/* end: FirstName */}
  {/* begin: LastName */}
  <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Last Name*"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "lastname"
            )}`}
            name="lastname"
            {...formik.getFieldProps("lastname")}
          />
          {formik.touched.lastname && formik.errors.lastname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastname}</div>
            </div>
          ) : null}
        </div>
        {/* end: FirstName */}
        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email*"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Phone Number"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "phonenum"
            )}`}
            name="phonenum"
            {...formik.getFieldProps("phonenum")}
          />
          {formik.touched.phonenum && formik.errors.phonenum ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phonenum}</div>
            </div>
          ) : null}
        </div>
    

        {/* begin: Terms and Conditions */}
        {/* <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              I agree the Terms & Conditions
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            // disabled={
            //   formik.isSubmitting ||
            //   !formik.isValid ||
            //   !formik.values.acceptTerms
            // }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
