import axios from "../../../../httpAxios";
import { globalvar } from "../../../../globalconstantvar";
const GetSettingDetails_URL = "api/company/GetSettingDetails";
const SaveSettings_URL = "api/company/SaveSettings";
const TimeZoneURL = "api/company/get-all-timezones";

export function GetSettingDetails(CompanyToken) {
    debugger;
    return axios.get(GetSettingDetails_URL + "?companytoken=" + CompanyToken ,{ baseURL: globalvar.FFApiBaseUrl })    
}

export function SaveSettings(model) {
    debugger;
    return axios.post(SaveSettings_URL, model, { baseURL: globalvar.FFApiBaseUrl });
}


export function getTimeZoneData() {
    return axios.get(TimeZoneURL);
}

