import React, {Suspense, lazy, } from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import { useSelector } from "react-redux";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const DashboardWidgets = lazy(() =>
  import("./modules/DashboardManagement/DashboardManagement")
);
const MeetingPage = lazy(() =>
//import("./modules/Setup/pages/Setup")
  import("./modules/ScheduleMeeting/Meeting")
);

const Users = lazy(() =>
  import("./modules/UserManagement/UserManagement")
);

const Forms = lazy(() =>
  import("./modules/FormManagement/FormManagement")
);


const Unavailability = lazy(() =>
  import("./modules/UserUnavailabilty/UserUnavailability")
);

const CompanyMaster = lazy(() =>
  import("./modules/CompanyManagement/CompanyManagement")
);

const DepartmentMaster = lazy(() =>
    import("./modules/Department/Department")
);
const SettingsInfo = lazy(() =>
  import("./modules/Settings/SettingsInfo")
);

const Setup = lazy(() =>
  import("./modules/Setup/pages/Setup")
);

const UserProfilePage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const Reports = lazy(() =>
import("./modules/Setup/pages/Setup")
);

const UserEmailTemplate = lazy(() =>
import("./modules/UserEmailTemplate/UserEmailTemplateMain")
);

const EmailTemplate = lazy(() =>
import("./modules/Admin/EmailTemplate/EmailTemplateMain")
);

const Companies = lazy(() =>
import("./modules/Admin/Company/CompanyMain")
);

const MeetingRequestPageobj = lazy(() =>
  import("./modules/MeetingRequest/MeetingRequestPage")
);

const Requester = lazy(() =>
  import("./modules/RequesterManagement/RequesterManagement")
);

const Schedule = lazy(() =>
    import("./modules/Schedule/Schedule")
);

const ExecutiveSchedule = lazy(() =>
    import("./modules/ExecutiveSchedule/ExecutiveSchedule")
);

const Calendar = lazy(() =>
    import("./modules/Calendar/Calendar")
);

export default function BasePage() {
  const { user } = useSelector(state => state.auth);
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect
  
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
          
          {user.role=="Super Admin"?
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/companies"/>
                }
                <ContentRoute path="/companies" component={Companies}/>
                <ContentRoute path="/email-template" component={EmailTemplate}/>
                
            </Switch>:
            <Switch>
                {
                    <Redirect exact from="/" to="/meetingrequests/list"/>
                }
                <ContentRoute path="/dashboard" component={DashboardWidgets}/>
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                <ContentRoute path="/meeting" component={MeetingPage}/>
                <ContentRoute path="/meetingsreport" component={Reports}/>
                <ContentRoute path="/Report/MeetingReport/:id" component={Reports}/>  
                <ContentRoute path="/user" component={Users}/>
                <ContentRoute path="/form" component={Forms}/>
                <ContentRoute path="/unavailability" component={Unavailability}/>        
                <ContentRoute path="/requester" component={Requester}/>
                <ContentRoute path="/meetingrequests" component={MeetingRequestPageobj}/>
                <ContentRoute path="/settings" component={SettingsInfo}/>

                <ContentRoute path="/company" component={CompanyMaster}/>
                <ContentRoute path="/department" component={DepartmentMaster}/>
                <ContentRoute path="/user-email-template/" component={UserEmailTemplate}/>
                <ContentRoute path="/setup" component={Setup}/>
                <ContentRoute path="/userprofile" component={UserProfilePage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Route path="/schedule" component={Schedule} />
                <Route path="/executiveschedule" component={ExecutiveSchedule} />
                <ContentRoute path="/calendar" component={Calendar} />
                <Redirect to="error/error-v1"/>
            </Switch>}
        </Suspense>
    );
}
