import React from "react";

export function Radio({ isSelected, onChange, name, text,style,disabled }) {
  
  return (
    <div style={style}>
      {/* Target this input: opacity 0 */}
      <input
        type="radio"
        className="custom-radio"
        name={name}
        id={text} // htlmlFor targets this id.
        checked={isSelected}
        onChange={onChange}    
        disabled={disabled}    
      /><span>&nbsp;{text}</span>
    </div>
  );
}
