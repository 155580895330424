import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register,IsCompanyHostHeaderExist,getTimeZoneData,getCompanyUserDetail,SaveUpdateCompanyDetails,getCountries,getTimeZoneDataByCountry} from "../_redux/authCrud";
import {useSelector} from "react-redux";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialValues = {
  firstname: "",
  company:"",
  phonenum:"",
  email: "",
  companyname:"",
  lastname: "",
  password: "",
  zipcode:"",
  address:"",
  domainname:"",
  timezones:"",
  country:"",
  confirmpassword: "",
  acceptTerms: true,
};

function CreateDomain(props) {
  debugger
  const { intl } = props;
  const [loading, setLoading] = useState(false); 
  const {user} = useSelector(state => state.auth);
  const [timezonedata, settimezonedata] = useState([])
  const [countrydata, setcountrydata] = useState([])
  const [companyobj,setCompanyobj] = useState(null)
  const [firstname,setFirstName] = useState("")
  const [lastname,setLastName] = useState("")
  const [email,setEmail] = useState("")
  const [companyname,setCompany] = useState("")
  const [userids,setUserIds] = useState("")
  const [cmpid,setcmpid] = useState(0)
  const [cmpsubdomain,setcmpsubdomain] = useState("")
  const [isvaliddomain,setisvalidsubmain] = useState("false")
  const [validdetails,setValidDetails]= useState("false")
  const [alreaadydone,setalreadydone]= useState("false")
  let  optionstimezones = null;
  
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const CompanyValidateSchema = Yup.object().shape({
    phonenum: Yup.string()
    .min(10, "Invalid Phone Number")
    .max(14, "Maximum 14 symbols")
    .matches(phoneRegExp, 'Phone number is not valid'),
    zipcode: Yup.string()
      .required('Please Enter ZipCode'
      ),
      domainname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        'Please Enter Subdomain'
      ),
      country: Yup.string()
      .required('Please Select Country'
      ),
      address: Yup.string()
      .required('Please Enter Address'
      ),      
      timezones: Yup.string()
      .required('Please Select TimeZone'
      ),      
      password: Yup.string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
      confirmpassword: Yup.string()
      .required('Please Enter your confirm password')
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Password and Confirm Password didn't match"
          ),
        }),
  
  });

  

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    enableLoading();
   
    let id = props.match.params && props.match.params.id ? props.match.params.id : 0;
    let userids =  props.match.params && props.match.params.userid ? props.match.params.userid : "";
    getCountries()
    .then(response=>{
      setcountrydata(response.data);
    })      

    getTimeZoneData()
        .then(response => {
            debugger;
            if (id == 0) {
                disableLoading()
            }
         //   settimezonedata(response.data);
           
            if (id) {
                getCompanyUserDetail(id,userids)
                    .then(dataresponse => {
                        debugger;
                        disableLoading();                          
                        const objDtt = dataresponse.data;
                        setcmpid(id);
                        setCompanyobj(objDtt.data.companyModel);
                        setFirstName(objDtt.data.firstName);
                        setLastName(objDtt.data.lastName);
                        setEmail(objDtt.data.email);
                        setCompany(objDtt.data.companyModel.name)
                        setUserIds(objDtt.data.userId);
                        setValidDetails("true")
                        if(objDtt.data.companyModel.hostHeader == null || objDtt.data.companyModel.hostHeader == "")
                        {
                          setalreadydone("false");
                        }
                        else
                        {
                          setalreadydone(objDtt.data.companyModel.hostHeader);
                        }
                    }).catch(error  => {                        
                        debugger;
                        setValidDetails("false");
                        if (error.response) {
                          //  error.response.data.message
                        }
                    }).finally(ress => {
                        debugger;
                    })
            }

        })
}, []);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const changeSelectCountryHandler = (value) => {
    console.log(value);
  //  alert('test');
    let vcntry = 0;
    if(value != "")
    {
        vcntry = parseInt(value);
    }
    getTimeZoneDataByCountry(vcntry)
    .then(dataresponse => {        
      debugger;
      settimezonedata(dataresponse.data);
    //  optionstimezones = dataresponse.data.map((el) => <option value={el.timeZonesId} key={el.timeZonesId}>{el.timeZoneName}</option>);
    }).catch(error  => {                        
        debugger;
      
        if (error.response) {
          //  error.response.data.message
        }
    }).finally(ress => {
        debugger;
    })
    debugger;
    }

    const domainchangeblur = (e) => {
        
      if(e.currentTarget.value != "")
      {
        IsCompanyHostHeaderExist(cmpid,e.currentTarget.value)
        .then(dataresponse => {        
          debugger;
           if(dataresponse.data.status == "Error")
           {
            toast.error(dataresponse.data.message)
            setisvalidsubmain("false");
            window.scrollTo({
              top: 0, 
              behavior: 'smooth'
              /* you can also use 'auto' behaviour
                 in place of 'smooth' */
            });
           }
           else
           {
            setisvalidsubmain("true");
           }
        //  optionstimezones = dataresponse.data.map((el) => <option value={el.timeZonesId} key={el.timeZonesId}>{el.timeZoneName}</option>);
        }).catch(error  => {                        
            debugger;          
            if (error.response) {
              toast.error(error.response.data.message,{autoClose:false})
              setisvalidsubmain("false");
              window.scrollTo({
                top: 0, 
                behavior: 'smooth'
                /* you can also use 'auto' behaviour
                   in place of 'smooth' */
              });
              //  error.response.data.message
            }
        }).finally(ress => {
            debugger;
        })
      }      
      else
      {
        setisvalidsubmain("false");
      }
    }

  const formik = useFormik({
    initialValues,
    validationSchema: CompanyValidateSchema,
    onSubmit: (values, { setStatus, setSubmitting,resetForm}) => {
      if(isvaliddomain == "true")
      {
        setSubmitting(true);
        enableLoading();
        var vll = parseInt(values.timezones);
        var vllcntry = parseInt(values.country);
        const objCMP = {
          CompanyId: companyobj.companyId,
          Name: companyobj.name,
          Address: values.address,
          ZipCode: values.zipcode ,
          PhoneNumber:values.phonenum,
          CountryId:vllcntry,
          TimeZonesId: vll,
          HostHeader:values.domainname,
          IsActive:true,
          CreateAt:companyobj.createAt,
          UpdatedAt:companyobj.updatedAt,
          UserName:email,
          Password:values.password
      
      }
     // const objCMP = null;
      SaveUpdateCompanyDetails(objCMP)
          .then(response => {
              debugger;
              toast.success(response.data.message)
           //   alert(response.data.message);
              window.location.reload();
          //   if(response.data.status=='Success'){
          //     alert('Saved Successfully.')
          //     resetForm(initialValues)
          //     props.history.push("/auth/login")
          //     //clearStateHandler();
            
          //   }
          //   if(response.data.status=='Exists'){
          //     alert('User already exists');
          //   }
          //   if(response.data.status=='Error'){
          //     alert('User not created');
          //   }
            //props.register(accessToken);
            disableLoading();
            setSubmitting(false);
          })
          .catch(error  => {                        
              debugger;
              if (error.response) {
                //  error.response.data.message
              }
              setSubmitting(false);
              toast.error(error.response.data.message,{autoClose:false})
              window.scrollTo({
                top: 0, 
                behavior: 'smooth'
                /* you can also use 'auto' behaviour
                   in place of 'smooth' */
              });
           //   setStatus(
            //    error.response.data.message
                // intl.formatMessage({
                //   id: "AUTH.VALIDATION.INVALID_LOGIN",
                // })
           //   );
              disableLoading();
          });  
      }
      else
      {
        toast.error("SubDomain is assigned to other company or subdomain is not valid",{autoClose:false});
      }	
    },
  });

  return (
    <div>
    {validdetails != "false" && alreaadydone == "false" &&
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Sign up for 14-Days Free Trial Account 
        </h3>
        <p className="text-muted font-weight-bold">
          Thank you for confirming your email, please fill up the form below to complete the registration process.
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}
        <h3>User Info</h3>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="First Name"
            type="text"
            value={firstname}
            readOnly
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "firstnm"
            )}`}
            name="firstname"            
          />        
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Last Name"
            type="text"
            value={lastname}
            readOnly
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "lastnm"
            )}`}
            name="lastname"            
          />        
        </div>
      
        <h3>Organization Info</h3>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Company Name*"
            type="text"
            value={companyname}
            readOnly
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "companyname"
            )}`}
            name="companyname"            
          />        
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="*Subdomain"
            style={{ width:'65%',display:'inline-block',textAlign:'right'}}
            {...formik.getFieldProps("domainname")}    
            type="text"
            onBlur={(e) =>{
              //handleChange('Date', option);
              //formik.handleChange(event);
              debugger;
              setcmpsubdomain(e.currentTarget.value)             
              domainchangeblur(e);
              }} 
           
            className={`form-control form-control-solid frmsubdmn h-auto py-5 px-6 ${getInputClasses(
              "domainname"
            )}`}
            name="domainname"        
          
          /><span className="spnn" >.eplannerpro.com</span>     
           {formik.touched.domainname && formik.errors.domainname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.domainname}</div>
            </div>
          ) : null}  
        </div>
        <div class="text-muted font-weight-bold" style={{ marginTop:'0px',marginBottom:'14px'}} >Above is your own site address <br/> (e.g. yourcompany.eplannerpro.com) to access the online platform for your ePlannerPro account. Only alphanumeric characters are allowed.</div>
        {/* begin: Address */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Address*"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "address"
            )}`}
            name="address"
            {...formik.getFieldProps("address")}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.address}</div>
            </div>
          ) : null}
        </div>
        {/* end: Address */}
  {/* begin: Zipcode */}
  <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Zip Code*"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "zipcode"
            )}`}
            name="zipcode"
            {...formik.getFieldProps("zipcode")}
          />
          {formik.touched.zipcode && formik.errors.zipcode ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.zipcode}</div>
            </div>
          ) : null}
        </div>
        {/* end: zipcode */}
        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Phone Number"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "phonenum"
            )}`}
            name="phonenum"
            {...formik.getFieldProps("phonenum")}
          />
          {formik.touched.phonenum && formik.errors.phonenum ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phonenum}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
            <select 
            {...formik.getFieldProps("country")} 
            value={formik.values.country}
            onChange={(option,event) =>{
//handleChange('Date', option);
//formik.handleChange(event);
formik.values.country = option.target.value;
changeSelectCountryHandler(option.target.value)

}}   name="country"  className={`form-control form-control-solid h-auto py-5 px-6 `}   >
                <option value="" >Select Country</option>
                {countrydata.map((tm) => (
                                                    <option value={tm.countryId}>
                                                        {tm.name}
                                                    </option>
                                                ))}              
            </select>
            {formik.touched.country && formik.errors.country ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.country}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
            <select name="timezones"  className={`form-control form-control-solid h-auto py-5 px-6 `}  {...formik.getFieldProps("timezones")} >
            
          <option value="">Select TimeZone</option>
                                                {timezonedata.map((tm) => (
                                                    <option value={tm.timeZonesId}>
                                                        {tm.timeZoneName}
                                                    </option>
                                                ))} 
            </select>
            {formik.touched.timezones && formik.errors.timezones ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.timezones}</div>
            </div>
          ) : null}
        </div>
        <h3 style={{ marginTop:'25px',marginBottom:'10px'}} >User Account</h3>
         {/* begin: Email */}
         <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email*"
            type="email"
            value={email}
            readOnly        
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"            
          />          
        </div>
        {/* end: Email */}
        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}
{/* begin: Confirm Password */}
<div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "confirmpassword"
            )}`}
            name="confirmpassword"
            {...formik.getFieldProps("confirmpassword")}
          />
          {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.confirmpassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}
        

        {/* begin: Terms and Conditions */}
        {/* <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              I agree the Terms & Conditions
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            // disabled={
            //   formik.isSubmitting ||
            //   !formik.isValid ||
            //   !formik.values.acceptTerms
            // }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          {/* <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link> */}
        </div>
      </form>     
       </div>
         }

         {validdetails == "false" && 
          <div> 
              <h3>Invalid Page. Please check it valid url from email</h3>
          </div>          
          }
           {validdetails == "true" && alreaadydone != "false" && 
          <div style={{textAlign:'center'}}>
              <h2>Congratulations! your account has been activated.</h2> 
              <h4>This is the link to your organisation's ePlannerPro account: <a href={"https://"+alreaadydone+".eplannerpro.com"}>https://{alreaadydone}.eplannerpro.com</a> Please bookmark it!</h4>             
              <a href={"https://"+alreaadydone+".eplannerpro.com"}>
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Proceed to Login
            </button>
          </a>
          </div>          
          }
    </div> 
       
        
  );
}

export default injectIntl(connect(null, auth.actions)(CreateDomain));
