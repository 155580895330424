/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function Admin_AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        
        <li
          className={`menu-item ${getMenuItemActive("/companies/list")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/companies">
            <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Companies.svg")} />
            </span>
            <span className="menu-text">Companies</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/email-template/list")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/email-template">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/EmailTemplate.svg")} />
            </span>
            <span className="menu-text">Email Template</span>
          </NavLink>
        </li>
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
